import { createApp, defineAsyncComponent } from "vue";
import "../../public/styles/app.css";

import SvgVue from 'svg-vue3'
import VueLazyLoad from 'vue3-lazyload'
import VueSocialSharing from 'vue-social-sharing'

const VueScrollTo = require('vue-scrollto')

const app = createApp({
  components: {
    SocialIcons: defineAsyncComponent(() => import('./components/SocialMediaIcons.vue')),
    Slider: defineAsyncComponent(() => import('./components/Slider.vue')),
    ReviewSlider: defineAsyncComponent(() => import('./components/ReviewSlider.vue')),
    ContactForm: defineAsyncComponent(() => import('./components/ContactForm.vue')),
    PhotoGallery: defineAsyncComponent(() => import('./components/PhotoGallery.vue')),
  },

  data: () => ({
    openMenu: '',
    modalPhoto: '',
    modalVideo: '',
    scrollPosition: 0,
    activeSideBarNav: 'nothing',
  }),

  computed: {
    mobileMenu () {
      return this.openMenu === 'mobileMenu'
    },
    isScrolling () {
      return this.scrollPosition > 0
    },
  },

  mounted () {
    if (window.location.hash.substring(0, 1) === "#") {
      VueScrollTo.scrollTo(window.location.hash, 300, {
        easing: 'ease-out',
        offset: -100,
        force: true,
        cancelable: true,
      })
    }
  },

  methods: {
    toggleMenu (menu) {
      this.openMenu = (this.openMenu != menu ? menu : '')
    },
    handleScroll (event) {
      this.scrollPosition = window.pageYOffset != undefined ? window.pageYOffset : window.scrollY
    },
    scrollTo ( to, duration = 300, offset = -100) {
      VueScrollTo.scrollTo(to, duration, {
        easing: 'ease-out',
        offset: offset,
        force: true,
        cancelable: true,
      })
    },

    openProjectPhoto (img){
      this.modalPhoto = img
    },

    openVideo (video) {
      this.modalVideo = video
    },

    setNavItem(val) {
      this.activeSideBarNav = val
    },

  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

})

app.use(VueLazyLoad)
app.use(SvgVue, {
  svgPath: 'resources/svg',
  extract: false,
  svgoSettings: [
      { removeTitle: true },
      { removeViewBox: false },
      { removeDimensions: true }
  ]
})
app.use(VueSocialSharing);

app.mount("#app")
